import '@material/web/button/filled-button';
import '@material/web/icon/icon';

import {html, css, unsafeCSS, nothing} from 'lit';
import {customElement, property, state} from 'lit/decorators.js'; // eslint-disable-line import/extensions
import {classMap} from 'lit/directives/class-map.js'; // eslint-disable-line import/extensions

import timeEstimateStyles from '../styles/time-estimate.scss?inline';
import {endpoints} from '../utils/endpoints';
import {EstimateChangeEventName, type EstimateChangeEvent} from '../utils/estimateChangeEvent';
import {estimateSpan} from '../utils/timeEstimate';

import {BaseAccordionItemElement} from './base-accordion-item';

// See SkillController@markExercise
interface MarkExerciseResponse {
  estimate: number;
  progress: number;
}

@customElement('exercise-item')
export class ExerciseItemElement extends BaseAccordionItemElement {
  @property({type: Number, attribute: 'exercise-id'})
  public accessor exercise = 0;

  @property({type: Number})
  public accessor estimate = 0;

  @property({type: Boolean})
  public accessor done = false;

  @state()
  private accessor _posting = false;

  protected override _defaultTitle = 'exercise-item';

  static styles = [
    ...BaseAccordionItemElement.styles,
    css`${unsafeCSS(timeEstimateStyles)}`,
    css`
      .title-link {
        padding-left: 0;
      }
      .item-title {
        flex: 1 1 auto; // take up all remaining space
      }
      .left-icon {
        padding: 0 5px;
      }
      .done-icon {
        color: var(--exercise-done-icon-color);
      }
      .body-footer {
        overflow: hidden;
      }
      .done-button {
        --md-filled-button-container-color: var(--exercise-done-button-color);
        --md-filled-button-icon-color: var(--exercise-done-text-color);
        --md-filled-button-label-text-color: var(--exercise-done-text-color);
        --md-filled-button-container-shape: 3px;
        --md-filled-button-label-text-weight: 400;
        float: right;
        font-size: 14px;
        margin: 0.5rem 0;
        padding: 0.4rem 0.6rem;
        text-transform: uppercase;
      }
    `,
  ];

  protected override _headerLeftTemplate() {
    return html`
      <span class="left-icon">
        <md-icon class="${classMap({'done-icon': this.done})}">
          ${this.done ? 'done' : 'class'}
        </md-icon>
      </span>
    `;
  }

  protected override _headerRightTemplate() {
    return estimateSpan(this.estimate);
  }

  protected override _bodyFooterTemplate() {
    return html`
      <div class="body-footer">
        <md-filled-button
          class="done-button ${this.done ? 'done' : 'not-done'}"
          ?disabled="${this._posting}"
          @click="${this.done ? this._markAsNotDone : this._markAsDone}"
        >
          ${this.done
            ? html`
                <md-icon slot="icon">done</md-icon>
              `
            : nothing
          }
          Ik ben klaar
        </md-filled-button>
      </div>
    `;
  }

  private _markAsDone() {
    this._markExercise(true);
    this.openNext();
  }

  private _markAsNotDone() {
    this._markExercise(false);
  }

  private async _markExercise(status: boolean) {
    // Eagerly update local state
    this.done = status;

    if (!this._posting) {
      this._posting = true;
      await $http
        .post<MarkExerciseResponse>(endpoints.post.markExercise, {
          id: this.exercise,
          status: status,
        })
        .then((response) => {
          const event: EstimateChangeEvent = new CustomEvent(EstimateChangeEventName, {
            detail: {
              estimate: response.data.estimate,
              progress: response.data.progress,
            },
          });
          window.dispatchEvent(event);
        })
        .catch(() => {
          // Restore done status if request fails
          this.done = !status;
          M.toast({
            text: 'Fout bij opslaan',
            classes: 'alert alert-danger',
          });
        })
        .finally(() => this._posting = false);
    }
  }
}
