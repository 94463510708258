/* eslint-disable no-use-before-define */

// Definitions here should match the visible properties in App/Models/Docentenpagina

export interface PageCategory {
  id: number;
  name: string;
  slug: string;
  indexPage: Page;
  pages: Array<Page>;
}

export interface Page {
  id: number;
  title: string;
  slug: string;
  url: string;
  content: string;
  category: PageCategory;
  parent: Page;
}

export enum VideoQuality {
  Low = 360,
  Default = 720,
  High = 1080,
}
