import '@material/web/button/elevated-button';
import '@material/web/iconbutton/icon-button';
import '@material/web/icon/icon';
import '@material/web/progress/circular-progress';

import {LitElement, html, css, nothing} from 'lit';
import {customElement, property, query, state} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {DisableBodyScroll} from './mixins/disableBodyScroll';
import {type ModalElement} from './modal';
import './modal'; // eslint-disable-line no-duplicate-imports
import {fetchFileTask, type FetchFileTask} from './utils/fileTask';
import {isSmallScreen} from './utils/screenSize';

@customElement('pdf-content')
export class PdfContentElement extends DisableBodyScroll(LitElement) {
  @property({type: String, attribute: 'pdf'})
  public accessor fileUuid = '';

  @query('#modal')
  private accessor modal: ModalElement | null = null;

  private _pdfTask: FetchFileTask = fetchFileTask(this);

  @state()
  private accessor _firstOpened = false;

  static styles = css`
    :host {
      display: block;
      margin: 1rem 0;
    }
    .dialog-button {
      --md-sys-color-primary: var(--pdf-text-color); /* Used for button text */
      --md-elevated-button-container-color: var(--pdf-button-color);
      --md-elevated-button-container-shape: 3px;
      --md-elevated-button-label-text-size: 18px;
      --md-elevated-button-label-text-weight: 400;
      --md-elevated-button-icon-size: 24px;

      text-transform: uppercase;
    }
    .loading-indicator {
      --md-circular-progress-active-indicator-color: var(--pdf-spinner-color);
      --md-circular-progress-size: 30px;
    }
    .pdf-content {
      height: 100%;
      width: 100%;
      border: none;
    }
  `;

  render() {
    return html`
      <md-elevated-button
        class="dialog-button"
        ?disabled="${!this._pdfTask.isInteractable}"
        has-icon
        @click="${this._openModal}"
      >
        ${this._pdfButtonContent()}
      </md-elevated-button>

      <modal-element
        id="modal"
        @open="${this._onOpenModal}"
        @close="${this._onCloseModal}"
      >
        <span slot="title">${this._pdfTask.value?.title}</span>
        <div class="pdf-content">
          ${this._pdfObjectContent()}
        </div>
      </modal-element>
    `;
  }

  private _pdfButtonContent() {
    return this._pdfTask.render({
      initial: () => html`<md-icon slot="icon">book</md-icon>Openen`,
      pending: () => html`
        <md-icon slot="icon">book</md-icon>
        <md-circular-progress class="loading-indicator" indeterminate></md-circular-progress>
      `,
      complete: (file) => html`<md-icon slot="icon">book</md-icon>${file.title}`,
      error: () => html`<md-icon slot="icon">error</md-icon>Bestand niet gevonden`,
    });
  }

  private _pdfObjectContent() {
    return this._firstOpened
      ? this._pdfTask.render({
        complete: (file) => html`
          <object class="pdf-content" data="${file.url}" type="application/pdf">
            <iframe class="pdf-content" src="${file.url}"></iframe>
          </object>
        `,
      })
      : nothing;
  }

  private async _openModal() {
    this._firstOpened = true;
    await this._pdfTask.boot();
    if (isSmallScreen()) {
      window.open(this._pdfTask.value?.url, '_blank'); // open pdf in new window on small-screen devices
    } else {
      await this.modal?.open();
    }
  }

  private _onOpenModal() {
    this._disableBodyScroll();
  }

  private _onCloseModal() {
    this._restoreBodyScroll();
  }
}
