import '@material/web/button/elevated-button';
import '@material/web/iconbutton/icon-button';
import '@material/web/icon/icon';

import {LitElement, html, css} from 'lit';
import {customElement, property, query} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import plyrStyles from 'plyr/dist/plyr.css?url';
import plyrIconUrl from 'plyr/dist/plyr.svg?url';

import {DisableBodyScroll} from './mixins/disableBodyScroll';
import {type ModalElement} from './modal';
import './modal'; // eslint-disable-line no-duplicate-imports
import {fetchVideoTask, type FetchVideoTask} from './utils/fileTask';
import {isSmallScreen} from './utils/screenSize';

import {VideoQuality} from '@/js/types/models/docentenpagina';

const defaultPlyrOptions: Plyr.Options = {
  autoplay: true,
  iconUrl: plyrIconUrl,
  settings: ['quality', 'speed'],
  keyboard: {
    global: true,
  },
  quality: {
    default: VideoQuality.Default,
    options: [VideoQuality.High, VideoQuality.Default, VideoQuality.Low] satisfies Array<VideoQuality>,
  },
  speed: {
    selected: 1,
    options: [0.75, 1, 1.25, 1.5, 1.75, 2],
  },
};

const resolvePlyr = () => import('plyr').then((plyrModule) => plyrModule.default);
const createPlyrInstance = async(targets: string | HTMLElement | Array<HTMLElement>, options: Plyr.Options = {}) =>
  resolvePlyr().then((Plyr) => new Plyr(targets, {...defaultPlyrOptions, ...options}));

@customElement('video-content')
export class VideoContentElement extends DisableBodyScroll(LitElement) {
  @property({type: String, attribute: 'video'})
  public accessor fileUuid = '';

  @query('#modal')
  private accessor modal: ModalElement | null = null;

  @query('#video-player')
  private accessor videoPlayer: HTMLVideoElement | null = null;

  private _plyr: Plyr | null = null;

  private _currentTime = 0;

  private _videoTask: FetchVideoTask = fetchVideoTask(this);

  private get _plyrSources(): Array<Plyr.Source> {
    return this._videoTask.value?.files.map((file) => ({
      src: file.url,
      size: file.quality,
      type: 'video/mp4',
    })) ?? [];
  }

  static styles = css`
    :host {
      display: block;
      margin: 1rem 0;
    }
    .dialog-button {
      --md-sys-color-primary: var(--video-text-color); /* Used for button text */
      --md-elevated-button-container-color: var(--video-button-color);
      --md-elevated-button-container-shape: 3px;
      --md-elevated-button-label-text-size: 18px;
      --md-elevated-button-label-text-weight: 400;
      --md-elevated-button-icon-size: 24px;

      text-transform: uppercase;
    }
    .loading-indicator {
      --md-circular-progress-active-indicator-color: var(--video-spinner-color);
      --md-circular-progress-size: 30px;
    }
    video:not(.plyr:fullscreen video) {
      max-height: calc(90vh - 4rem); /* This is a hack to ensure proper sizing; see #3753 */
    }
  `;

  render() {
    return html`
      <link rel="stylesheet" href="${plyrStyles}" />

      <md-elevated-button
        class="dialog-button"
        ?disabled="${!this._videoTask.isInteractable}"
        has-icon
        @click="${this._openModal}"
      >
        ${this._videoButtonContent()}
      </md-elevated-button>

      <modal-element
        id="modal"
        @open="${this._onOpenModal}"
        @close="${this._onCloseModal}"
      >
        <span slot="title">${this._videoTask.value?.title}</span>
        <video id="video-player" preload="none"></video>
      </modal-element>
    `;
  }

  constructor() {
    super();
    resolvePlyr(); // eager load Plyr
  }

  private _videoButtonContent() {
    return this._videoTask.render({
      initial: () => html`<md-icon slot="icon">videocam</md-icon>Openen`,
      pending: () => html`
        <md-icon slot="icon">videocam</md-icon>
        <md-circular-progress class="loading-indicator" indeterminate></md-circular-progress>
      `,
      complete: (video) => html`<md-icon slot="icon">videocam</md-icon>${video.title}`,
      error: () => html`<md-icon slot="icon">videocam_off</md-icon>Video niet gevonden`,
    });
  }

  private async _initializePlayer() {
    // Construct new Plyr instance
    const plyr = await createPlyrInstance(this.videoPlayer!);

    plyr.source = {
      type: 'video',
      title: this._videoTask.value?.title,
      sources: this._plyrSources,
    };

    // Add listeners to run once the player is ready to go
    plyr.on('ready', () => {
      // If currentTime was saved when last closing modal, skip to currentTime
      plyr.on('loadeddata', () => {
        if (this._currentTime !== 0) {
          plyr.currentTime = this._currentTime;
        }
      });

      // Prevent going back to 'old' saved timestamp
      plyr.on('qualitychange', () => {
        this._currentTime = 0;
      });
    });

    return plyr;
  }

  private _openInFullscreen(plyr: Plyr) {
    plyr.on('exitfullscreen', () => {
      this._closeModal(); // ensure we close the modal when closing fullscreen
    });
    plyr.on('ready', (e) => {
      e.detail.plyr.fullscreen.enter();
    });
  }

  private async _openModal() {
    await this._videoTask.boot();
    this.modal?.open();
  }

  private async _onOpenModal() {
    this._disableBodyScroll();
    this._plyr ??= await this._initializePlayer();
    if (isSmallScreen()) {
      this._openInFullscreen(this._plyr); // automatically enter fullscreen on small devices
    }
  }

  private _closeModal() {
    this.modal?.close();
  }

  private _onCloseModal() {
    if (this._plyr) {
      // Save currentTime so we can resume later
      this._currentTime = this._plyr.currentTime;
      this._plyr.destroy();
      this._plyr = null;
    }
    this._restoreBodyScroll();
  }
}
