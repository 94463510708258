import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js'; // eslint-disable-line import/extensions

@customElement('animatable-page')
export class AnimatablePageElement extends LitElement {
  @property({type: String})
  public accessor title = '';

  @property({type: Boolean})
  public accessor disabled = false;

  // @ts-expect-error -- This unused variable is here because for no obvious reason accessors above are not parsed properly without it
  private _hack = '';

  static styles = css`
    :host {
      display: block;
      padding: 1rem 0;
      height: auto;
    }
  `;

  render() {
    return html`<slot></slot>`;
  }
}
